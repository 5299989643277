import { Switch, Route, Redirect } from 'react-router-dom'
import Users from 'components/AdminDashboard/Users/Users'
import Partners from './Partners/Partners'
import './MainContainer.scss'

function MainContainer() {
  return (
    <div id="main-container">
      <Switch>
        <Route path="/users" component={Users} />
        <Route path="/partners" component={Partners} />
        <Redirect to="/users" />
      </Switch>
    </div>
  )
}

export default MainContainer
